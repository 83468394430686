<div class="carousel fLeft wellscore">
    <h3>Wellbeing-score</h3>
    <p>Your current lifestyle, health and stress factors:</p>
		<div class="set-size charts-container">
      <div class="pie-wrapper progress-{{score}} style-2">
        <span class="label" style="font-size: 16px;padding-top: 45px;font-weight: bold;color: navy;">Your Wellbeing</span>
        <!-- <span class="label">{{score}}/<span class="smaller">100</span> -->  <span class="label-{{labelScore}}">{{scoreRate}}</span> 
        <div class="pie">
          <div class="left-side half-circle"></div>
          <div class="right-side half-circle"></div>
        </div>
        <div class="shadow"></div>
        </div>
    </div>
            <br>
            <button class ="btn-bl" (click)="loadView('questionnaire')">Update</button>
            <button class ="btn-wh" (click)="loadView('suggested-resource')">View evaluation</button>
</div>


    <div class="notification-container block_bg fRight firstime">
		<!-- inject improve health component-->
		<app-improve-health></app-improve-health>
	</div> 