import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { QuestionnaireService } from '../shared/services/questionnare.service';
import { FormGroup, FormControl, Validators, FormBuilder, FormArray } from '@angular/forms';
import { distinctUntilChanged } from 'rxjs/operators';
import { GlobalDataService } from '../shared/global-data';

interface AnswerMapper {
  questionId: number;
  answerId: string;
  score: string;
}

interface SubmitMapper {
  idNumber: string;
  fullName: string;
  questionnaire: string
  answers: AnswerMapper[]
  // answers: FormArray
}

@Component({
  selector: 'app-questionnaire',
  templateUrl: './questionnaire.component.html',
  styleUrls: ['./questionnaire.component.css']
})


export class QuestionnaireComponent implements OnInit {
  answers: AnswerMapper[] = [];
  queId: String = '1161';

  questionnaire_form: FormGroup;
  //checkbox_form: FormGroup;
  idNumber: string = '';
  firstName: string = '';
  lastName: string = '';
  fullName: string = '';
  questionnaireList: any;
  buttonCheck: boolean = false;
  isLoading: boolean = false;
  showQuestionArray: boolean = false;
  allAnswered: boolean = false;
  isSubmitted: boolean = false;
  questionIds = {
    STRESS_12: 12,
    STRESS_13: 13,
    STRESS_14: 14,
    STRESS_3: 3,
    STRESS_4: 4,
    STRESS_5: 5,
    STRESS_6: 6,
    STRESS_7: 7,
    STRESS_8: 8,
    STRESS_9: 9,
    STRESS_10: 10,
    STRESS_33: 33,
    STRESS_34: 34,
    STRESS_35: 35
  };


  questionnaireResponse: [QuestionnaireInfo.GetQuestionnaire];
  SubmitQuestionnaireResponse: [QuestionnaireInfo.SubmitQuestionnaireResponse];

  constructor(private router: Router,
    private queServ: QuestionnaireService,
    private formbuilder: FormBuilder, private globalData: GlobalDataService) {

    this.questionnaire_form = this.formbuilder.group({
      questionArray: this.formbuilder.array([])
    });

  }

  get questionArray() {
    return this.questionnaire_form.controls["questionArray"] as FormArray;
  }

  addRadioQuestionController(questionID) {

    const questiongrp = this.formbuilder.group({
      questionId: [questionID],
      // answerId: 0,
      description: [""],
      score: ["0"],
      answerId: [''],

    });
    this.questionArray.push(questiongrp);
    this.answers.push({
      questionId: questionID,
      answerId: "",
      score: "0"
    });
  }

  addCheckboxQuestionController(questionID) {

    const questiongrp = this.formbuilder.group({
      questionId: [questionID],
      // answerId: 0,
      description: [""],
      score: [0],
      answerId: [''],
    });
    this.questionArray.push(questiongrp);
    this.answers.push({
      questionId: questionID,
      answerId: "",
      score: "0"
    });
  }


  ngOnInit(): void {
    
    this.questionnaireList = JSON.parse(localStorage.getItem('questionnaire'));

    console.log("this.questionnaireList...", this.questionnaireList);

    let counter = 0;

    this.questionnaireList.forEach(question => {

      if (question.isRadio == 'Y') {
        this.addRadioQuestionController(question.questionId);
      }
      else {
        this.addCheckboxQuestionController(question.questionId);
      }
      question.isAnswered = false;

      counter++;
      if (counter == this.questionnaireList.length) {
        this.showQuestionArray = true;
      }
    });

    this.idNumber = JSON.parse(localStorage.getItem('userIdNumber'));
    this.firstName = JSON.parse(localStorage.getItem('firstName'));
    this.lastName = JSON.parse(localStorage.getItem('surname'));
    this.fullName = this.firstName.concat(this.lastName.toString());
  
  }

  ansObj: AnswerMapper[] = [];
  submitObj: SubmitMapper;


  doesAnswerExist(questionId) {
    return this.ansObj.filter(obj => {
      return obj.questionId === questionId;
    });
  }

  onRadioChange(e, qIndex, i) {
    this.answers[qIndex].answerId = this.questionArray.value[qIndex].answerId
    this.answers[qIndex].score = this.questionnaireList[qIndex].answers[i].score
  }

  onCheckboxChange(e, qIndex, i) {

    const formArray: FormArray = this.questionnaire_form.get('answerId') as FormArray;

    if (e.target.checked) {

      if (e.target.checked == true && e.target.value == 1 && e.target.parentNode.childNodes[0].id == 3) {
      //   alert("1");
      //   $("#4").prop("checked", true); 
      // } else {
      //   alert("false")
      }

      this.answers[qIndex].answerId = this.answers[qIndex].answerId + e.target.value;
      this.answers[qIndex].score = this.answers[qIndex].score + this.questionnaireList[qIndex].answers[i].score

    } else {

      let str = e.target.value;
      let strIndex = this.answers[qIndex].answerId.indexOf(str);
      console.log(strIndex);
      if (this.answers[qIndex].answerId.length == 1) {
        this.answers[qIndex].answerId = "";
        this.answers[qIndex].score = "";
      } else if (strIndex == 0) {
        this.answers[qIndex].answerId = this.answers[qIndex].answerId.substring(strIndex + 1);
        this.answers[qIndex].score = this.answers[qIndex].score.substring(strIndex + 1);
      } else if (strIndex == this.answers[qIndex].answerId.length - 1) {
        this.answers[qIndex].answerId = this.answers[qIndex].answerId.substring(0, strIndex);
        this.answers[qIndex].score = this.answers[qIndex].score.substring(0, strIndex);
      } else {
        this.answers[qIndex].answerId = this.answers[qIndex].answerId.substring(0, strIndex) + this.answers[qIndex].answerId.substring(strIndex + 1);
        this.answers[qIndex].score = this.answers[qIndex].score.substring(0, strIndex) + this.answers[qIndex].score.substring(strIndex + 1);

        console.log(this.answers[qIndex].answerId);
      }
    }
  }

  submitTest() {
    this.isLoading = true;
    this.allAnswered = true;
    this.isSubmitted = true;

    for (let index = 0; index < this.questionArray.value.length; index++) {
      const response = this.questionArray.value[index];

      if (response.answerId == "") {
        this.allAnswered = false;
        this.questionnaireList[index].isAnswered = false;
      }
      else {
        this.allAnswered = true;
        this.questionnaireList[index].isAnswered = true;
      }

        if (index == this.questionArray.value.length - 1) {

      if (this.allAnswered) {

        this.submitObj = ({ idNumber: this.idNumber, fullName: this.fullName, questionnaire: '1161', answers: this.answers });

        this.queServ.submitQuestionnaire(this.submitObj).subscribe(result => {
          if (result !== undefined) {

            localStorage.setItem('submitQueResponse', JSON.stringify(result));
            this.SubmitQuestionnaireResponse = result;
            this.globalData.changeCurrentViewState('well-being-score');
            this.router.navigate(['well-being-score']);
          }
        });

      }else {
        console.log ("no answer");
        $("html, body").animate({ scrollTop: $('.title').offset() });
        // missing answer handling

        this.isLoading = false;
      }
      }
    }


  }

  getAnswerForQuestion(questionId: number) {
    return this.answers.find((currAnswer) => currAnswer.questionId === questionId);
  }

  displayQuestion(questionnaire: QuestionnaireInfo.GetQuestionnaire) {

    if (questionnaire.questionId === this.questionIds.STRESS_14) {
      const stress1Answer = this.getAnswerForQuestion(this.questionIds.STRESS_13);
      // Display these stress follow-up questions if the first stress question has been answered and is 'Yes'
    
      return !stress1Answer || Number(stress1Answer.answerId) !== 1;

    } else if (questionnaire.questionId === this.questionIds.STRESS_5) {
      const stress3Answer = this.getAnswerForQuestion(this.questionIds.STRESS_4);
   // Display these stress follow-up questions if the first stress question has been answered and is 'Yes'
 
      return !stress3Answer || Number(stress3Answer.answerId) !== 1;
 
    } else if (questionnaire.questionId === this.questionIds.STRESS_7) {
      const stress5Answer = this.getAnswerForQuestion(this.questionIds.STRESS_6);
     
      // Display these stress follow-up questions if the first stress question has been answered and is 'Yes'
      return !stress5Answer || Number(stress5Answer.answerId) !== 1;

    } else if (questionnaire.questionId === this.questionIds.STRESS_10) {
      const stress9Answer = this.getAnswerForQuestion(this.questionIds.STRESS_9);
    
      // Display these stress follow-up questions if the first stress question has been answered and is 'Yes'
      return !stress9Answer || Number(stress9Answer.answerId) !== 1;

    } else if (questionnaire.questionId === this.questionIds.STRESS_35) {
      const stress34Answer = this.getAnswerForQuestion(this.questionIds.STRESS_34);
     
      // Display these stress follow-up questions if the first stress question has been answered and is 'Yes'
      return !stress34Answer || Number(stress34Answer.answerId) !== 1;
    }

    return true;
  }
}