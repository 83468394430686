<!-- Top Action Blocks -->
<div class="action-blocks">
	<!-- Home page top buttons -->
	<div class="hp-buttons" *ngIf="!isCaseView && !isWellBeingView" [hidden]="isFaq || isSaps">
		<div class="block loadModal" data-loadmodal="link_reqCallback" 
			(click)="loadModal('callback')">
			<div class="title"><i class="icon i-req_callback"></i> <span>Request a call back</span></div>
		</div>
		<div class="block loadView" (click)="loadView('case')">
			<div class="title"><i class="icon i-req_service"></i> <span>Request for Service</span></div>
		</div>
		<div class="block loadModal" data-loadmodal="emergency" 
			(click)="loadModal('emergency')">
			<div class="title"><i class="icon i-emergency"></i> <span>Emergency</span></div>
		</div>
		<div class="block loadModal" 
			data-loadmodal="emergency" 
			(click)="openWellbeing()">
			<div class="title">
				<span class="icon heart-{{scorecolor}}">&#9825; </span>
				<span>My wellbeing programme</span><br />
			</div>
		</div>		
	</div>
	<!-- Service top buttons for case view -->
	<div class="service-buttons" *ngIf="isCaseView" [hidden]="isFaq || isSaps">
		<div class="block loadView border_sr" 
		data-loadview="link_selfReferral" 
		(click)="changeStep('1')" 
		[ngClass]="{ 'active' : activeStep == 1 }">
			<div class="arrow-right arrow_sr"></div>
			<i class="icon i-self_referral"></i>			
			<div class="title">
				<span>Self referral</span>
				<p>Employee refers themselves into the {{isNedBank?'EWP':'EAP'}}</p>
			</div>
		</div>
		<div class="block loadView border_manr" 
		data-loadview="link_manReferral" 
		(click)="changeStep('2')"  
		[ngClass]="{ 'active' : activeStep == 2 }">
			<div class="arrow-right arrow_manr"></div>
			<i class="icon i-managerial_referral"></i>
			<div class="title">
				<span>Managerial referral</span>
				<p>Manager refers an employee into the {{isNedBank?'EWP':'EAP'}}</p>
			</div>
		</div>
		<div class="block loadView border_ci" 
		data-loadview="link_critIncident" 
		(click)="changeStep('3')"  
		[ngClass]="{ 'active' : activeStep == 3 }">
			<div class="arrow-right arrow_ci"></div>
			<i class="icon i-critical_incident"></i>
			<div class="title">
				<span>Critical incident/Trauma Support</span>
				<p>Managers request for debriefing event</p>
			</div>
		</div>
		<div class="block loadModal border_emer" 
		data-loadmodal="emergency" 
		(click)="changeStep('4')" 
		[ngClass]="{ 'active' : activeStep == 4 }">
			<div class="arrow-right arrow_emer"></div>
			<i class="icon i-emergency"></i>
			<div class="title">
				<span>Emergency</span>
				<p>Emergency {{isNedBank?'EWP':'EAP'}} services request</p>
			</div>
		</div>		
	</div>
	<!-- Service top buttons for wellbeing-score -->
	<div class="service-buttons" style="position: relative;" *ngIf="isWellBeingView" [hidden]="isFaq || isSaps">
		<h3 class="block-title">Home<span class="arrow"> > </span>
		<span *ngIf="isScoreHeaderCheck && headerTitleControl"> {{ scoreHeaderConc }} 
		<span class="arrow"> > </span></span>
		<span *ngIf="isQueHeaderCheck"> {{ queHeaderConc }} 
		<span class="arrow"> > </span></span> 
		<span class="current-header"> {{ headerType }} </span> </h3>
		
		<hr>
		<div class="block loadView border_srt " style="width: 27.2% !important;" 
		(click)="changeStep('1')" 
		[ngClass]="{ 'active' : activeStep == 1 }">
		<span class="circle circle_sr"></span>	
			<div class="title">
				<span>Wellbeing-score</span>
				<p>How are you doing?<br>  Take a lifestyle, health and stress check and receive a personalised<br> plan to help you achieve optimal wellbeing.</p>
			</div>
		</div>
		<div class="block loadView border_manrt" style="width: 27.2% !important;"
		(click)="changeStep('5')"  
		[ngClass]="{ 'active' : activeStep == 5 }">
		<div class="circle circle_manr"></div>
			<div class="title">
				<span>Wellness cafe</span>
				<p>A resource portal to empower you with knowledge,<br> esources and tools from leading experts to support you on<br> your journey to wellbeing.</p>
			</div>
		</div>
		<div class="block loadView border_cit" style="width: 27.2% !important;"
		(click)="changeStep('6')"  
		[ngClass]="{ 'active' : activeStep == 6 }">
		<div class="circle circle_ci"></div>
			<div class="title">
				<span>Wellbeing  resources</span>
				<p>Personal recommendations to help you manage your stress <br> better and ways to improve your lifestyle habits  to  achieve <br> optimal wellbeing.</p>
			</div>
		</div>		
	</div>
	<!-- FAQ top button -->
	<div class="faq-buttons" *ngIf="isFaq" [hidden]="isSaps">
		<div class="block">
			<div class="title">FAQ's</div>
		</div>		
	</div>

	<!-- SAPS report top buttons -->
	<div class="report-buttons" *ngIf="isSaps">
		<div class="breadcrumbs">
			<a routerLink='/reports-dashboard'>Dashboard</a> 
			<span>&rsaquo;</span>
			<span class="dynamic-breadcrumb-text">Reports</span> 
		</div>		
	</div>	
	
</div> <!-- ./ Top Action Blocks -->