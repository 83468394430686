import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { GlobalDataService } from '../../../shared/global-data';
import { SecurityService } from '../../../shared/services/security.service';
import { SynergyService } from '../../../shared/services/synergy.service';
import { PASSWORD_REGEX } from '../../../shared/utilities';
import { StorageMap } from '@ngx-pwa/local-storage';
import { CasesService } from '../../../shared/services/cases.service';
import { ReportsService } from '../../../shared/services/reports.service';
import { concat, forkJoin, interval } from 'rxjs';
import { GoogleAnalyticsService } from "../../../shared/services//google-analytics-service.service";
import * as Enums from '../../../shared/enums';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

    loginForm: FormGroup;
    errMsg: any = '';
    successMsg: any = '';
    isLoading: boolean = false;
    accountLockedTries: number = 3;
    newLoginMomWellness: any;
    profileUsername: string;
    profileNext: string;
    

    constructor(private router: Router, 
        private globalData: GlobalDataService,
        private securityServ: SecurityService,
        private fb: FormBuilder,
        private lsService: StorageMap,
        private synServ: SynergyService,
        private caseServ: CasesService,
        private reportsService: ReportsService,
        private googleAnalyticsService: GoogleAnalyticsService
        ) { }

    ngOnInit() {
        $('body').addClass('logged-out-view');
        this.loginForm = this.fb.group({
            username: ['', [Validators.required, Validators.minLength(4)]],
            password: ['', [Validators.required, Validators.minLength(6), Validators.pattern(PASSWORD_REGEX)]],
        });   
        
        this.newLoginMomWellness = localStorage.getItem('loadMomWellnessStylesheet');
            if (this.newLoginMomWellness == "true") {
                this.profileUsername = "Email";
                $('.profileUsername').show();
                $('.profilepassword').hide();
                $('.i-submit').hide();
                $('.i-next').show();
                localStorage.setItem('ProfileNext', "true");
            
            } else {
                this.profileUsername = "Username";
                $('.i-next').hide()
            }
    }

    loginMomentumwellnessNext(){
                this.profileNext = localStorage.getItem("ProfileNext");
                    if (this.profileNext == "true") {
                        $('.i-next').hide();
                        $('.i-submit').show();
                    } else {
                        $('.i-next').show();
                    }  
                $('.profileUsername').hide();
                $('.profilepassword').show();
        
            this.login();
    }

    login(){
        this.googleAnalyticsService.pushDirectEvent('Login Click', 'Member MHWwellness Login - Post Login', 'Member MHWwellness Login - Post Login');
        this.googleAnalyticsService.pushDirectEvent('Login Click', 'Member MHWwellness Login - Post Login', 'Member MHWwellness Login - Post Login');
        this.isLoading = true;
        this.errMsg = '';
        let loginObj = {
            username: this.loginForm.get('username').value,
            password: this.loginForm.get('password').value
           
        }

        /* first authenticate for token */
        this.securityServ.authenticate(loginObj).subscribe(data => {     
                if(typeof data.id_token != 'undefined'){
                    /* set global bearer token */
                    localStorage.setItem('grepsislwr29',JSON.stringify(data.id_token));
                    /* now attempt login */
                    this.securityServ.login(loginObj).subscribe((resp) => {
                        // set synergy GUIDs
                        let getEmail = resp.body.emailAddress;
                        localStorage.setItem('userIdNumber',JSON.stringify(resp.body.idNumber));
                        localStorage.setItem('username', resp.body.username);
                        localStorage.setItem('firstName',JSON.stringify(resp.body.firstname));
                        localStorage.setItem('surname',JSON.stringify(resp.body.surname));

                        let hasGroups = this.checkForSAPSgroups(resp.body.groups);
                        // resp.body.groups != null

                        if(getEmail == null || getEmail == ""){
                            this.errMsg = `User profile incomplete. Please contact 080 0111 223 to resolve the issue.`;  
                            this.isLoading = false; 
                        } else {
                            this.synServ.findUser(getEmail).subscribe(data => {
                                if(data.body.results.length > 0){
                                    let empStatus = data.body.results[0].CustomerStatus;
                                    if(empStatus == 'A'){ // allow login if customer is ACTIVE status
                                        let getGuid1 = data.body.results[0].CompanyCodeDebtorAccountID;
                                        let getGuid2 = data.body.results[0].Parent;
                                        let empNo = data.body.results[0].TextFreeField10;
                                        let name = data.body.results[0].ContactFirstName;
                                        let reportsFlag = data.body.results[0].IntegerFreeField5;
                                        let showReports;
                                        let isSaps = hasGroups;
                                        let companyCode = data.body.results[0].CompanyCode; 
                                        let ParentCode = data.body.results[0].ParentCode; 
                                        localStorage.setItem("ParentCode", ParentCode);
                                        let loadMomWellnessStylesheet;
                                        if(companyCode == "895") { // Momentum
                                            loadMomWellnessStylesheet = true;
                                        } else { // Metropolitan default (code = 881)
                                            loadMomWellnessStylesheet = false;
                                        }

                                        if(reportsFlag == 1){
                                            showReports = true;
                                        } else {
                                            showReports = false;
                                        }
                                        let allData = {
                                            getGuid1: getGuid1,
                                            getGuid2: getGuid2,
                                            empNo: empNo,
                                            name: name.toString().toLowerCase(),
                                            showReports: showReports,
                                            activateLifestyleIcon: data.body.results[0].YesNoFreeField3,
                                            isSaps: isSaps,
                                            idNumber: resp.body.idNumber,
                                            passportNumber: resp.body.passportNumber,
                                            isNedBank:data.body.results[0].Parent?.toLowerCase()==Enums.NED_BANK_ID ? true : false,
                                            uniqueId:resp.body.uniqueId,
                                            mmiClientId:Enums.NED_BANK_ID,
                                            username:resp.body.username,
                                            firstname:resp.body.firstname,
                                            surname:resp.body.surname,
                                            emailAddress:resp.body.emailAddress,
                                            cellularNumber:resp.body.cellularNumber
                                        }
                                        this.globalData.changeSynergyDataState(allData);
                                        this.lsService.set('synergyData', allData).subscribe(() => {});
                                        localStorage.setItem('guid1',JSON.stringify(getGuid1));
            
                                        // load case data for all 3 case types into LS
                                        if(!isSaps){
                                            this.loadCaseData('maincases_eap');
                                            this.loadCaseData('maincases_managerial');
                                            this.loadCaseData('maincases_cisd');
                                        }
            
                                        // set profile data
                                        this.lsService.set('profileData', allData).subscribe(() => {
                                            setTimeout(() => {
                                                // share profile data state
                                                this.globalData.changeProfileDataState(allData);                                
                                                this.globalData.changeLoggedInState(true);
                                                this.isLoading = false;
                                                if(isSaps){
                                                    if(hasGroups){
                                                        this.loadGroupHierachyData(resp.body.groups);
                                                    } else {
                                                        this.router.navigate(['/reports-dashboard']);
                                                    }
                                                } else {
                                                    this.router.navigate(['/dashboard']);
                                                }
                                            }, 5000);
                                        });
                                    } else {
                                        this.isLoading = false;
                                        this.errMsg = `User is currently de-activated in our system. 
                                        Please contact 080 0111 223 to resolve the issue.`;                                    
                                    }
                                } else {
                                    this.isLoading = false;
                                    // this.errMsg = 'User does not exist. Please contact 080 0111 223 to resolve the issue.';
                                    this.errMsg = 'User does not exist. Please contact ithelpdesk@momentum.co.za to resolve the issue.';
                                }
                            });
                            this.lsService.set('loginData', resp.body).subscribe(() => {});
                        }
                        
                    }, err => {
                        console.log('couldnt login...API error!');
                    })
                } else {
                    this.isLoading = false;
                    this.errMsg = `Our system could not log you in due to a technical error. 
                        Please contact our support team to resolve your query`;                    
                }           
        }, err => {
            this.isLoading = false;
            this.accountLockedTries--;
            if(err.error.errorMessage.indexOf('ACCOUNT_LOCKED') > -1){
                this.errMsg = `Your account has been locked. Click on 'I forgot my password' to re-activate your account.`;
                //this.unlockAccount();
            }else if(err.error.errorMessage.indexOf('USER_DOES_NOT_EXIST') > -1) {
                    this.errMsg = 'User does not exist. Please contact ithelpdesk@momentum.co.za to resolve the issue.';
            }else if(err.status == 401 || err.status == 404){
                let getUsername = this.loginForm.get('username').value;
                if(this.accountLockedTries > 1){
                    this.errMsg = `Your login details are incorrect. You have ${this.accountLockedTries} login attemps left.`;
                } else {
                    this.errMsg = `Your login details are incorrect. You have ${this.accountLockedTries} login attempt left.`;
                }
            }
        })
    }

    failMsg(){
        this.isLoading = false;
        this.errMsg = `Unfortunately we could not unlock your account due to a 
            tehcnical error. Please contact us on 080 0111 223 to resolve the issue.`;        
    }

    unlockAccount(){
        this.isLoading = true;
        this.securityServ.getUserData(this.loginForm.get('username').value).subscribe(resp => {
            if(resp.statusCodeValue == 200){
                let getGuid = resp.body[0].uniqueId;
                this.securityServ.unlockAccount(getGuid).subscribe(resp => {
                    if(resp.statusCodeValue == 200){
                        this.errMsg = '';
                        this.successMsg = `Your account has been unlocked. 
                            Please wait while we auto-sign you in...`;
                            this.login();
                    } else {
                        this.failMsg();
                    }
                }, err => {
                    this.failMsg();
                });              
            } else {
                this.failMsg();             
            }
        }, err => {
            this.failMsg();
        });
    }

    storeALLCaseDetails(respData,type){
        let getRequestIds = respData;
        let observables = getRequestIds.map(id => this.caseServ.getCaseDetails(id));
        forkJoin(observables).subscribe(data => {
            this.lsService.set(type,data).subscribe();
        }, err => {
            console.log('we had a concat RXJS error along the way...');
        });
    }    

    loadCaseData(type){
        let servType = type.replace('maincases_','');
        this.caseServ.getCases(servType).subscribe(cases => {
            let res = cases.body.results;
            if(res.length > 0){
                // set MAIN CASES LS
                this.lsService.set(type,res).subscribe();
                this.globalData.changeMainCaseDataState(res);              
            }
            let getRequestIds = res.map(i => i.RequestID);
            this.storeALLCaseDetails(getRequestIds,servType);
        });
    }

    checkForSAPSgroups(groupArray){
        let hasSapsGroups: boolean = false;
        if(groupArray != null){
            let filterArr = groupArray.filter(i => i.indexOf('SAPS') > -1).length;
            if(filterArr > 0){
                hasSapsGroups = true;
            } else {
                hasSapsGroups = false;
            }
        }
        return hasSapsGroups;
    }

    loadGroupHierachyData(data){
        this.reportsService.getReportsHierachy(data).subscribe(res => {
            if(res.statusCodeValue == 200){
                let dataStore = res.body.reports;
                let hasOccHealth = typeof dataStore["Occupational Health"] != "undefined" ? 'Y' : 'N';
                let hasIllHealth = typeof dataStore["Ill Health"] != "undefined" ? 'Y' : 'N';
                let hasCOFs = typeof dataStore["Certificate of Fitness"] != "undefined" ? 'Y' : 'N';
                let hasWEllnessEventReport = typeof dataStore["Wellness Event"] != "undefined" ? 'Y' : 'N';
                if(hasOccHealth == 'Y'){
                    let occHealthLabels = Object.keys(dataStore["Occupational Health"]);
                    this.lsService.set('hasOccHealth', hasOccHealth).subscribe();
                    localStorage.setItem('occHealthData',JSON.stringify(dataStore["Occupational Health"]));
                    localStorage.setItem('occHealthLabels',JSON.stringify(occHealthLabels));                    
                }
                if(hasIllHealth == 'Y'){
                    let illHealthLabels = Object.keys(dataStore["Ill Health"]);
                    this.lsService.set('hasIllHealth', hasIllHealth).subscribe();
                    localStorage.setItem('illHealthData',JSON.stringify(dataStore["Ill Health"]));
                    localStorage.setItem('illHealthLabels',JSON.stringify(illHealthLabels));                 
                }
                if(hasCOFs == 'Y'){
                    //let cofLabels = Object.keys(dataStore["Certificate of Fitness"]);
                    let cofLabels = [
                        { apiLabel: "SHE", uiLabel: "SHE" },
                        { apiLabel: "EXTERNAL-DEPLOYMENT", uiLabel: "External Deployment" },
                        { apiLabel: "PRE-EMPLOYMENT", uiLabel: "Pre-Employment Trainees" }
                    ];
                    this.lsService.set('hasCOFs', hasCOFs).subscribe();
                    localStorage.setItem('cofData',JSON.stringify(dataStore["Certificate of Fitness"]));
                    localStorage.setItem('cofLabels',JSON.stringify(cofLabels));                 
                }

                if(hasWEllnessEventReport == 'Y'){
                   let  wellnessEventLabels = Object.keys(dataStore["Wellness Event"]).sort();
                    this.lsService.set('hasWEllnessEventReport', hasWEllnessEventReport).subscribe();
                    localStorage.setItem('wellnessEventData',JSON.stringify(dataStore["Wellness Event"]));
                    localStorage.setItem('wellnessEventLabels',JSON.stringify(wellnessEventLabels));                 
                }

                // FINALLY NOW route to reports dashboard
                this.router.navigate(['/reports-dashboard']);             
            } else {
                console.log('!!data could not be retrieved from "getReportsHierachy" API call!!');
            }
        });
    }
}
