<div class="login-banner">
	<!--<img src="../assets/img/health_profilebanner.png" alt="">-->
</div>
<div class="health-profile-hero">
	<h1>REGISTRATION</h1>
	<p>Complete the form below to register your user profile.</p>
</div>
<div class="reg-form">
	<!--<h3>REGISTRATION</h3>-->
	
	<!-- form -->
	<div class="step1" *ngIf="currStep == 1">
		<form class="regForm" [formGroup]="regForm">
			<div class="input-row clearfix">
				<input type="text" formControlName="empNumber" name="empNumber" placeholder="Employee Number">
				<input type="text" formControlName="email" name="email" placeholder="Email Address" class="fRight">
			</div>
			<div class="input-row clearfix">
				<input type="text" formControlName="firstname" name="firstname" placeholder="Name">
				<input type="text" formControlName="surname" name="surname" placeholder="Surname" class="fRight">
			</div>
			<div *ngIf="isDis == 'true' ? false : true" class="input-row clearfix">
				<input
				  type="text"
				  formControlName="contactNo"
				  name="contactNo"
				  placeholder="Contact Number"
				/>
				<input
				  type="text"
				  formControlName="idNumber"
				  name="idNumber"
				  placeholder="ID Number *"
				  class="fRight"
				/>
			  </div>
			  <div *ngIf="isDis == 'true' ? true : false" class="input-row clearfix">
				<div
				  style="
					display: -webkit-inline-box;
					width: 40%;
					padding: 15px;
					font-size: 14px;
					margin-left: -105px;
				  "
				>
				  <label style="width: 40%">
					<input type="radio" (click)="OnClick('id')" name="searchType" value="id" formControlName="searchType" />
					ID Number
				  </label>
				  <label style="width: 50%">
					<input type="radio" (click)="OnClick('passport')" name="searchType" value="passport" formControlName="searchType" />
					Passport Number
				  </label>
				</div>
				<input
				  type="text"
				  formControlName="contactNo"
				  name="contactNo"
				  placeholder="Contact Number"
				  class="fRight"
				/>
				<!-- <input type="text" formControlName="passportNumber" name="passportNumber" placeholder="Passport Number" class="fLeft" style="margin-left: 80px;"> -->
			  </div>
			  <div *ngIf="isDis == 'true' ? true : false" class="input-row clearfix">
				
				<input *ngIf="regForm.get('searchType').value === 'id'"
				  type="text"
				  formControlName="idNumber"
				  name="idNumber"
				  placeholder="ID Number *" class="fLeft" style="margin-left: 80px;"
				/>
				<input *ngIf="regForm.get('searchType').value === 'passport'"
				  type="text"
				  formControlName="passportNumber"
				  name="passportNumber"
				  placeholder="Passport Number *" class="fLeft" style="margin-left: 80px;"
				/>
			  </div>
			<!--
			<div class="input-row clearfix">
				<input type="text" formControlName="username" name="username" placeholder="Username">
				<input type="text" class="fRight" style="visibility: hidden;">
			</div>-->		
		</form>
		<div class="loader" *ngIf="isLoading"></div>
		<p class="red-error-text" *ngIf="errorMsg.length > 1">{{ errorMsg }}</p>
		<p *ngIf="userExists" class="red-error-text">
			This user already exists in our system. Please <a routerLink="/login">click here</a> to login
		</p>
		<div [hidden]="isLoading">
			<button class="submit-btn btn" 
				(click)="register()" 
				[disabled]="regForm.status == 'INVALID'">Register</button>
		</div>
		<p *ngIf="isDis=='true'?true:false"><b>Disclaimer:</b> Your ID number or your passport number is the unique identifier used to create your digital profile in the Momentum ecosystem</p>
	</div>
	<!-- success screen -->
	<div class="step step2" *ngIf="currStep == 2">
		<div class="success-block">
			<i class="icon i-check_circle"></i>
			<h4>Success</h4>
			<p>You have successfully been registered on our system. 
				Please check your email inbox for confirmation and further instructions.</p>
		</div>		
	</div>
</div>