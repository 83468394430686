import { Component, OnInit } from '@angular/core';
import { GlobalDataService } from '../../../shared/global-data';
import { Router } from '@angular/router';
import { StorageMap } from '@ngx-pwa/local-storage';
import { GoogleAnalyticsService } from "../../../shared/services//google-analytics-service.service";
import { SecurityService } from 'src/app/shared/services/security.service';
import * as Enums from '../../../shared/enums';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

    loggedIn: boolean = false;
    isSars: boolean = false;
    isSaps: boolean = false;
    isMomWellness: boolean = false;
    showElearning: boolean = false;
    IsNedBank:boolean=false;
    isModalClosed: boolean ;
    isSiteMomWellness = localStorage.getItem('loadMomWellnessStylesheet');
    userData:any;
    //clear timeout variable
    clearTimeout: any;

    constructor(private router: Router, private globalData: GlobalDataService, private googleAnalyticsService: GoogleAnalyticsService,
        private lsService: StorageMap, private service:SecurityService) { }

    ngOnInit() {
        this.globalData.isLoggedIn.subscribe(val => {
            if(val){
                this.loggedIn = true;
                this.elearningCheck(); // Load E-Learning Navbar Check
                this.autoLogout();
                this.lsService.get('profileData').subscribe((lsData: any) => {
                    if(typeof lsData !== 'undefined'){
                        this.userData = lsData;
                        this.isSaps = lsData.isSaps;
                        this.isMomWellness = lsData.loadMomWellnessStylesheet;
                        this.IsNedBank = lsData.isNedBank;
                    }
                });

            }
        });
        this.isModalClosed = true;
        
    }

    loadDashboard(){
        this.router.navigate(['/dashboard']);
    }

    //auto logout when token expire
    autoLogout(){
        //do this after 1 hour
        this.clearTimeout = setTimeout(()=>{
            this.logOut();
        },1000*60*60);
    }

    logOut(){
        this.loggedIn = false;
        localStorage.removeItem('grepsislwr29');
        localStorage.removeItem('guid1');
        
        this.lsService.delete('loginData').subscribe(() => {});
        this.lsService.delete('profileData').subscribe(() => {});
        this.lsService.delete('synergyData').subscribe(() => {});

        this.lsService.delete('maincases_eap').subscribe(() => {});
        this.lsService.delete('maincases_managerial').subscribe(() => {});
        this.lsService.delete('maincases_cisd').subscribe(() => {});

        this.lsService.delete('eap').subscribe(() => {});
        this.lsService.delete('managerial').subscribe(() => {});
        this.lsService.delete('cisd').subscribe(() => {});    

        // clear reports LS items
        this.lsService.delete('hasOccHealth').subscribe(() => {});
        localStorage.removeItem('occHealthData');
        localStorage.removeItem('occHealthLabels');
        
        this.lsService.delete('hasIllHealth').subscribe(() => {});
        localStorage.removeItem('illHealthData');
        localStorage.removeItem('illHealthLabels');

        this.lsService.delete('hasCOFs').subscribe(() => {});
        localStorage.removeItem('cofData');
        localStorage.removeItem('cofLabels');

        
        this.lsService.delete('hasWEllnessEventReport').subscribe(() => {});
        localStorage.removeItem('wellnessEventData');
        localStorage.removeItem('wellnessEventLabels');

        this.globalData.changeLoggedInState(false);
        $('body').removeClass('mini-menu');
        this.router.navigate(['/login']);

        //clear timeout when use logout
        if(this.clearTimeout){
            clearTimeout(this.clearTimeout);
            console.log('timer cleared');
        }
    }

    elearningCheck(){
		let str = localStorage.getItem('ParentCode');
		let ParentCode = str.replace(/\s/g, '');
        
        if(this.isSiteMomWellness && ParentCode == "60088"){
            this.isSars = false;
            this.showElearning = true;
        }else if(ParentCode == "60088" && !this.isSiteMomWellness) { //SARS -South African Revenue Service
			this.showElearning = true;
			this.isSars = true;
		} else {
			this.showElearning = true;
			this.isSars = false;
		}
	}

    //Google Analytics Click for MHWellness
    elearningnavbarMHwellness(){
        this.googleAnalyticsService.pushDirectEvent('Elearning Navbar Click', 'Member MHWwellness ELearning - Post Login','Member MHWwellness Elearning Navbar - Post Login');
        this.googleAnalyticsService.pushDirectEvent('Elearning Navbar Click', 'Member MHWwellness ELearning - Post Login','Member MHWwellness Elearning Navbar - Post Login');
    }
    //Google Analytics Clicks for MomentumWellness
    elearningnavbarMomentumwellness(){
        this.googleAnalyticsService.pushDirectEvent('Elearning Navbar Click', 'Member MomentumWellness ELearning - Post Login','Member MomentumWellness Elearning Navbar - Post Login');
        this.googleAnalyticsService.pushDirectEvent('Elearning Navbar Click', 'Member MomentumWellness ELearning - Post Login','Member MomentumWellness Elearning Navbar - Post Login');
    }
	
    //modal toggle
    toggleModal(){
        this.isModalClosed = !this.isModalClosed;
    }
   
    // go to sar
    goToSarsFinancialWellness(){
        window.open("https://www.momentumfp.co.za/share/profile/69726dc3-4017-4bb3-801c-b649208698e0", '_blank');
    }

    //Call HLC API
    goToHLCSite()
    {
        // alert("hello");
        let url  = Enums.API_HLC_PATHS;
        let id = Enums.HLC_CLIENT_ID;
        let key = Enums.HLC_CLIENT_SECRET;
        //console.log(url);
        //window.location.href = "https://mmi.myhealth360.co.za";  
        //return;
        let obj={
            "grant_type":"",
            "client_id":id,
            "client_secret":key
        };
        let data = this.userData;
        this.service.authenticateHLC(obj).subscribe(
            (res)=>{
                console.log("success");
                //debugger;
                // window.location.href = "https://mmi.myhealth360.co.za";
                const token = res.access_token;
                let payLoad={
                    uniqueId : data.uniqueId,
                    mmiClientId : data.mmiClientId,
                    username : data.username,
                    firstname : data.firstname,
                    surname : data.surname,
                    emailAddress : data.emailAddress,
                    cellularNumber : data.cellularNumber
                };
                this.service.goToHLC(payLoad,token).subscribe(
                    (next)=>{
                        //window.location.href = "https://mmi.myhealth360.co.za";
                        localStorage.setItem("HLCAuth",JSON.stringify(next));
                        window.location.href = next.redirect;
                    },
                    (error)=>{
                        console.log("Error while calling go to HLC api",error);
                    }
                )  
            },
            (err)=>{
                console.log("Error while calling HLC api",err);
            }
        )
    }
}
