<div class="header clearfix">
	<img [hidden]="isSars" src="../assets/img/metropolitan_health_logo.jpg" 
		class="main-logo-mh_wellness fLeft">
	<img [hidden]="isSars" src="../assets/img/momentum_wellness_logo.jpg" 
		class="main-logo-mom_wellness fLeft">	
	<img *ngIf="isSars" src="../assets/img/sars.png" 
		class="main-logo-mom_sars fLeft">
	<img *ngIf="loggedIn && isSiteMomWellness && IsNedBank" src="../assets/img/NedbankLogo.png" 
		class="main-logo-mom_sars fRight" style="width: 60px; float: right;left: -15px;">		
	<div class="nav-tools fRight">
		<ul [hidden]="isSaps">
			<li *ngIf="loggedIn"><a routerLink="/dashboard">Home</a></li>
			<li class="link_our-services"><a href="https://www.mhwellness.co.za/default.aspx?xGX7Y5WBkjmsUlL7tVtZYYw8OudhAiSaMJwEH6YyvgI8IFCZDrEWQsevoLoPJ8tXhKtl+lkPHTJrPBsAHp5kkw==" target="_blank">Our Services</a></li>
			<li class="link_health-info"><a href="https://www.mhwellness.co.za/default.aspx?xGX7Y5WBkjkN5c5YaaxpPLMgVbydvRQemUomqytqXOsoDJQ1jS3Pt61wZ5xksDIIPPFJFDJ8cgtev7MiK3hknQ==" target="_blank">Health Information</a></li>
			
			<li *ngIf="loggedIn"><a href="http://d8ds98798sd98098dhgjhk2j1hk2j12.ehc.legalhero.co.za" target="_blank">Legal Services</a></li>
			<!-- <li *ngIf="loggedIn"><a class="link" (click)="isSars? goToSarsFinancialWellness() : toggleModal()" target="_blank">Financial Wellness</a></li> -->
			<li *ngIf="loggedIn && isSiteMomWellness && IsNedBank"><a  (click)="goToHLCSite()" class="link"  target="_blank">Community</a></li>
			<li *ngIf="loggedIn && showElearning"><a (click)="isSiteMomWellness ? elearningnavbarMomentumwellness(): elearningnavbarMHwellness()" routerLink="/e-learning" [innerHTML]="isSiteMomWellness ? 'Videos':'e-learning' "></a></li>
			<li class="link_contact-us-MH"><a href="https://www.mhwellness.co.za/default.aspx?xGX7Y5WBkjn/hvG5xoSlEwoojhLHL4YXaEl0E8mAQ0qQBLLQR3oV2pbfv4DKl7KJHEj0ivZTTqF+EJRHFyAeig==" target="_blank">Contact Us</a></li>
			<li class="link_contact-us-MOM"><a href="https://www.momentumwellness.co.za/contact-us" target="_blank">Contact Us</a></li>
			<li *ngIf="loggedIn"><a (click)="logOut()" class="link-logout">Log Out</a></li>
			<li *ngIf="!loggedIn"><a routerLink="/login">Login</a></li>
		</ul>

		<!-- SAPS REPORT SPECIFIC NAV -->
		<ul *ngIf="isSaps">
			<li *ngIf="loggedIn"><a routerLink="/reports-dashboard">Reports</a></li>
			<li *ngIf="loggedIn"><a routerLink="/cofs">Certificates</a></li>			
			<li *ngIf="loggedIn"><a (click)="logOut()" class="link-logout">Log Out</a></li>
			<li *ngIf="!loggedIn"><a routerLink="/login">Login</a></li>
		</ul>		
	</div>
</div> <!-- ./ header -->

<!-- modal  -->

<div class="overlay" [ngClass]="isModalClosed ? 'modal-hide' : '' ">
	<div class="modal">
		<div class="mod-header" [style.background-color]="isSiteMomWellness ? 'red' : 'blue' ">
			<h3 style="color: white;">Note!</h3>
			<span class="close-modal" style="color: white;" (click)="toggleModal()">x</span>
		</div>
		<div class="modal-content">
			<p>These tools are temporarily unavailable. Please contact your relevant tollfree number for further assistance</p>
		</div>
	</div>
</div>