<div class="row block_bg">
	<div class="column">
        <div class="page-header">
            <div class="title-container">
                <span>Improve your health</span>
            </div>
        </div>
		<div class="main-content">
            <p>Your status is:<span class="status"> <button style="display: none;" class="status-btn-red">RED</button> <button style="display: none;" class="status-btn-amber">AMBER</button> <button style="display: none;" class="status-btn-green">GREEN</button>  </span></p>
            <div>
                <p>{{Paragraph}}</p>
			<ul>
                <li>{{point1}}</li>
                <li class="dandc">{{point2}}</li>
                <li class="dandc">{{point3}}</li>
                <li class="dandc">{{point4}}</li>	
                <li class="dandc amberr">{{point5}}</li>
            </ul>
            <button class ="btn-bl" (click)="viewReport()" >View Report</button>
            <button class="btn-wh dandc" ><a style="text-decoration: none;" href="{{pdfURL}}" target="_blank">Download PDF Report</a></button>
            </div>
            <div>
            <span class="dandc"> What we recommend:</span>
            <div class="recommend dandc">
                <p style="margin-right: 200px;">Diabetes Programme</p>
             <button (click)="diabeteslink()" class ="btn-bl" target="_blank">Download PDF</button> 
             
             
            </div>
            <div class="recommend dandc">
             <p style="margin-right: 162px;">Healthy Heart Programme</p>
             <button (click)="downloadReport()" class ="btn-bl" target="_blank">Download PDF</button> 
            </div>
            </div>
		</div> 

	</div> 

</div> 
