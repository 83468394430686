let getDomain = location.origin;
// let HLCURL = "https://mmi.myhealth360.co.za";
let HLCURL = "https://eap-service.onrender.com";
let client_id="1234567890abcdef1234567890abcdef";
let client_secret="abcdef1234567890abcdef1234567890abcdef123456";
let ned_bank_id;
if(getDomain.indexOf('dev') > -1 || getDomain.indexOf('localhost') > -1){
	getDomain = 'https://healthhub-dev.momentumhealth.co.za';
	ned_bank_id="2745d201-cdd4-4e60-ac97-6fd43ada40f5";
} else if(getDomain.indexOf('tst') > -1){
	getDomain = 'https://healthhub-tst.momentumhealth.co.za';
	ned_bank_id="2745d201-cdd4-4e60-ac97-6fd43ada40f5";
} else if(getDomain.indexOf('pre') > -1){
	getDomain = 'https://healthhub-pre.momentumhealth.co.za';
	ned_bank_id = "1d64c41c-4c34-4285-a819-69c79edc133d";
} else {
	getDomain = 'https://healthhub.momentumhealth.co.za';
	ned_bank_id = "1d64c41c-4c34-4285-a819-69c79edc133d";
}
export const environment = {
  production: false,
  apiUrl: getDomain+'/hub/api',
  hlcURL: HLCURL,
  clientId : client_id,
  clientSecret : client_secret,
  ned_bank_id:ned_bank_id
};

// maybe could be this one: https://member-dev.mhwellness.co.za/eap
